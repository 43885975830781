<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "../../components/page-header.vue";
import PostsTable from "./posts-table";

export default {
    components: { Layout, PageHeader, PostsTable }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div
                    class="page-title-box d-flex align-items-center justify-content-between"
                >
                    <h4 class="mb-0 font-size-18">Posts Table</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item active">
                                House offers
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- /end page title -->

        <PostsTable></PostsTable>
    </Layout>
</template>
