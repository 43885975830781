<script>
import { tableData } from "./dataPostsTable";

/**
 * Advanced table component
 */
export default {
    data() {
        return {
            tableData: tableData,
            title: "Advanced Table",
            items: [
                {
                    text: "Tables",
                    href: "/"
                },
                {
                    text: "Advanced",
                    active: true
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "name", sortable: true },
                { key: "position", sortable: true },
                { key: "office", sortable: true },
                { key: "age", sortable: true },
                { key: "date", sortable: true },
                { key: "salary", sortable: true }
            ],
            editableTablefields: [
                { key: "name", label: "Name", type: "text", editable: true },
                {
                    key: "department",
                    label: "Department",
                    type: "select",
                    options: ["Accounting", "Development", "HR", "Marketing"],
                    editable: true
                },
                { key: "age", label: "Age", type: "number", editable: true },
                {
                    key: "dateOfBirth",
                    label: "Date Of Birth",
                    type: "date",
                    editable: true
                },
                {
                    key: "isActive",
                    label: "Is Active",
                    type: "checkbox",
                    editable: true
                }
            ],
            editableDataItems: [
                {
                    age: 40,
                    name: "Dickerson",
                    department: "Development",
                    dateOfBirth: "1984-05-20",
                    isActive: true
                },
                {
                    age: 21,
                    name: "Larsen",
                    department: "Marketing",
                    dateOfBirth: "1984-05-20",
                    isActive: false
                },
                {
                    age: 89,
                    name: "Geneva",
                    department: "HR",
                    dateOfBirth: "1984-05-20",
                    isActive: false
                },
                {
                    age: 38,
                    name: "Jami",
                    department: "Accounting",
                    dateOfBirth: "1984-05-20",
                    isActive: true
                }
            ]
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.tableData.length;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        }
    }
};
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Posts List</h4>
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div
                                id="tickets-table_length"
                                class="dataTables_length"
                            >
                                <label class="d-inline-flex align-items-center">
                                    Show&nbsp;
                                    <b-form-select
                                        v-model="perPage"
                                        size="sm"
                                        class="form-select form-select-sm"
                                        :options="pageOptions"
                                    ></b-form-select
                                    >&nbsp;entries
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div
                                id="tickets-table_filter"
                                class="dataTables_filter text-md-end"
                            >
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Search..."
                                        class="form-control form-control-sm ms-2"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                        <b-table
                            class="datatables"
                            :items="tableData"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                        ></b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div
                                class="dataTables_paginate paging_simple_numbers float-end"
                            >
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                    ></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
