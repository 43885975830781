import { render, staticRenderFns } from "./two-step-verification-2.vue?vue&type=template&id=970f7f54&scoped=true&"
import script from "./two-step-verification-2.vue?vue&type=script&lang=js&"
export * from "./two-step-verification-2.vue?vue&type=script&lang=js&"
import style0 from "./two-step-verification-2.vue?vue&type=style&index=0&id=970f7f54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "970f7f54",
  null
  
)

export default component.exports